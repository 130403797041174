
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getAlarmCount, getAlarmList } from '@/api/pushAlarm';
import { RoutingModule } from '@/store/modules/routing';
import { getNewAlarmCount } from '@/api/newAlarm';

@Component({
  name: 'SubLayoutHeader',
})
export default class extends Vue {
  @Prop({ required: true }) private board!: any;

  @Prop({ required: true }) private newAlarmCount!: any;

  mounted() {
    this.getAlarmList();
    this.getNewAlarmCount();
  }

  private showPopover = false;

  private alarmCount = 0;

  private alarmList = [];

  private alarmList2: any[][] = [];

  private totalElements = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 5,
  };

  private getNewAlarmCount() {
    getNewAlarmCount().then((res) => {
      this.newAlarmCount = res.data;
    });
  }

  private getAlarmCount() {
    getAlarmCount().then((res) => {
      this.alarmCount = res.data;
    });
  }

  private async getAlarmList() {
    await getAlarmList(this.listQuery).then((res) => {
      this.alarmList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
    const itemMap: Map<string, any[]> = new Map();
    this.alarmList.forEach((item: any) => {
      const date: string = item.createDate.substring(0, 10); // 날짜 부분만 추출
      if (!itemMap.has(date)) {
        itemMap.set(date, []);
      }
      const itemList: any[] | undefined = itemMap.get(date);
      if (itemList) {
        itemList.push(item);
      }
    });
    this.alarmList2 = Array.from(itemMap.values());
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getAlarmList();
  }

  private handleClickBefore() {
    if (this.$route.name === 'PostAdd') {
      this.$router.push({ name: 'BoardIndex', params: { boardUid: this.$route.params.boardUid } });
    } else if (this.$route.name === 'BoardIndex') {
      if (RoutingModule.Point === 'Mypage') this.$router.push({ name: 'Mypage' });
      else if (RoutingModule.Point === 'Home') this.$router.push({ name: 'Home' });
    } else if (this.$route.name === 'PostDetail') {
      this.$router.push({ name: 'BoardIndex', params: { boardUid: this.$route.params.boardUid } });
    }
  }
}
